<template>
  <div class="pt-2">
    <div v-if="block.type === 'pdf' && !isMobile">
      <block-pdf
        v-if="block.type === 'pdf'"
        class="pdf-block"
        :block="block"
      ></block-pdf>
    </div>
    <div v-if="block.type === 'image'">
      <img :src="`images/${block.image}`" style="width: 100%" />
    </div>
    <div v-if="block.type === 'link'" class="page-white">
      <div v-if="qrMode" class="download-qr">
        <img :src="'/images/qr/' + block.qrImage" alt="QR code" />
        <div v-html="block.qrText"></div>
      </div>
      <v-btn
        v-if="!qrMode"
        :href="block.url"
        target="_blank"
        class="mt-4 btn-override"
        :color="page.color"
        dark
        elevation="0"
      >
        <v-icon small class="mr-2">mdi-arrow-top-right</v-icon>
        <span v-html="block.text"></span>
      </v-btn>
    </div>
    <div v-if="block.type === 'video'">
      <block-video
        :block="block"
        class="video-block"
        :style="`border-color: ${page.color}`"
      ></block-video>
    </div>
    <div v-if="block.note" v-html="block.note"></div>
    <div class="sub-panels" v-if="block.type === 'blocks'">
      <v-expansion-panels v-if="block.blocks" focusable @change="changed">
        <v-expansion-panel v-for="(block, i) in block.blocks" :key="i">
          <v-expansion-panel-header
            class="mt-1 mb-0 text-subtitle-1 font-weight-bold"
            :style="`color: ${block.color}`"
            @click="toggle(block)"
            expand-icon="mdi-arrow-down"
          >
            <span v-html="block.title"></span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <block-panel-content
              :block="block"
              :page="page"
            ></block-panel-content>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </div>
</template>

<script>
import analytics from "@/services/analytics";
export default {
  name: "BlockPanelContent",
  props: ["page", "block"],
  components: {
    BlockVideo: () => import("@/components/blocks/BlockVideo.vue"),
    BlockPdf: () => import("@/components/blocks/BlockPdf.vue"),
    BlockPanelContent: () => import("@/components/blocks/BlockPanelContent.vue")
  },
  data: () => ({
    isMobile: false,
    qrMode: false
  }),
  methods: {
    toggle(block) {
      analytics.tab(block.title);
      if (block.type === "pdf" && this.isMobile) {
        this.$hub.$emit("showPdfPopup", block);
        this.tab = -1;
        return false;
      }
      this.$hub.$emit("pauseAllMedia", block);
    },
    changed() {
      this.$hub.$emit("startScreenSaverCheck");
    },
    setWidth() {
      this.isMobile = window.innerWidth < 1024;
    },
    extLink(url, img, text) {
      window.$app.extLink(url, img, text);
    }
  },
  created() {
    window.addEventListener("resize", this.setWidth);
  },
  mounted() {
    this.setWidth();
    this.qrMode = window.$app.qrMode;
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.setWidth);
  }
};
</script>

<style lang="scss">
.sub-panels {
  .v-expansion-panel::before {
    box-shadow: none !important;
  }
  .v-expansion-panel {
    background-color: initial !important;
    border-bottom: 1px solid #999999 !important;
  }
  .v-expansion-panel-header {
    span {
      font-size: 90%;
      color: #121212;
      font-weight: normal;
    }
    &::before {
      display: none;
    }
  }
  .v-expansion-panels {
    border-radius: 0 !important;
    border-top: 1px solid #999999 !important;
  }
  .v-expansion-panel-header__icon > .v-icon::before {
    color: #eb1600;
  }
}
.btn-override {
  border-radius: 24px;
  font-weight: normal;
  font-family: "JohnsonText-Medium", Arial, Helvetica, sans-serif;
  text-transform: none;
  letter-spacing: 0.25px;
}
.page-white {
  background-color: white;
  margin-top: -10px;
  padding: 10px 20px 20px;
  border-radius: 16px;
}
</style>
